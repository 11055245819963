// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-movee-mdx": () => import("./../../../src/pages/projects/movee.mdx" /* webpackChunkName: "component---src-pages-projects-movee-mdx" */),
  "component---src-pages-projects-nuclear-engine-mdx": () => import("./../../../src/pages/projects/nuclear-engine.mdx" /* webpackChunkName: "component---src-pages-projects-nuclear-engine-mdx" */),
  "component---src-pages-projects-table-mdx": () => import("./../../../src/pages/projects/table.mdx" /* webpackChunkName: "component---src-pages-projects-table-mdx" */),
  "component---src-pages-resume-mdx": () => import("./../../../src/pages/resume.mdx" /* webpackChunkName: "component---src-pages-resume-mdx" */)
}

